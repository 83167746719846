import { mapNodeToArticle } from '../../js/graphql/utils'
import Home from '../../js/components/page/Home'

function HomePage(props) {
  const { posts = [], ...rest } = props

  const highlight = mapNodeToArticle(posts[0])
  const entries = posts.slice(1).map(mapNodeToArticle)

  return (
    <Home highlight={highlight} entries={entries} {...rest} />
  )
}

export default HomePage
